import React, { useEffect, useState } from "react";
import "./bootstrap.min.css";
import { Button, Stack } from "react-bootstrap";
import _ from "lodash";

import { fetchAllData } from "./API";
import FilterInput from "./FilterInput";
import { filterConst } from "./constants";

function App() {
  const [data, setData] = useState([]);
  const [filterdData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [productName, setProductName] = useState("");
  const [size, setSize] = useState("");
  const [milk, setMilk] = useState("");
  const [whip, setWhip] = useState("");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    setFilteredData(getSingleMatchingItem());
  }, [type, productName, size, milk, whip]);

  /**
   * Loading data from API
   */
  const loadData = () => {
    setLoading(true);
    fetchAllData()
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      });
  };

  function getMatchingItems(upToField) {
    let filter = {};
    let filterStop = false;

    _.each(
      [
        [type, filterConst.type],
        [productName, filterConst.productName],
        [size, filterConst.size],
        [milk, filterConst.milk],
        [whip, filterConst.whip],
      ],
      function (field) {
        if (field[1] === upToField) {
          filterStop = true;
        }

        if (filterStop) {
          return;
        }

        if (field[0]) {
          filter[field[1]] = field[0];
        }
      }
    );

    return _.filter(data, filter);
  }

  function getSingleMatchingItem() {
    let matchingItems = getMatchingItems();

    if (matchingItems.length === 1) {
      return matchingItems[0];
    }

    return null;
  }

  /**
   *
   * @param {string} field
   * @returns list of options
   */
  const getOptions = (field) => {
    return _.uniq(getMatchingItems(field).map((item) => item[field]));
  };

  const hasSingleMilkOption = () => {
    // right now this is only true for Caramel Apple Spice drinks
    // but it is better than hardcoding it
    return getOptions(filterConst.milk).length === 1;
  };

  /**
   *
   * @param {Array} value selected value
   * @param {string} field
   * @returns void - returning nothing
   */
  const handleOnChange = (value, field) => {
    value = value.target.value;

    switch (field) {
      case filterConst.type:
        setType(value);

        if (!value.length) {
          handleResetFilters();
        }

        setProductName("");
        setSize("");
        setMilk("");
        setWhip("");

        break;
      case filterConst.productName:
        setProductName(value);

        setSize("");
        setMilk("");
        setWhip("");

        break;
      case filterConst.size:
        setSize(value);

        setMilk("");
        setWhip("");

        break;
      case filterConst.milk:
        setMilk(value);

        setWhip("");

        break;
      case filterConst.whip:
        setWhip(value);

        break;
      default:
        return null;
    }
  };

  const handleResetFilters = () => {
    setFilteredData([]);
    setType("");
    setProductName("");
    setSize("");
    setMilk("");
    setWhip("");
  };

  return (
    <div className="main-container">
      <div className="d-flex justify-content-md-center">
        {!loading && (
          <Stack gap={4} className="p-3 square border border-4 p-4">
            <FilterInput
              id="type-field"
              display={true}
              field={filterConst.type}
              options={getOptions(filterConst.type)}
              handleOnChange={handleOnChange}
              placeholder="Select type..."
              selected={type}
            />
            <FilterInput
              id="product-name-field"
              display={type?.length > 0}
              field={filterConst.productName}
              options={getOptions(filterConst.productName)}
              handleOnChange={handleOnChange}
              placeholder="Select product name..."
              selected={productName}
            />
            <FilterInput
              id="size-field"
              display={productName.length > 0}
              field={filterConst.size}
              options={getOptions(filterConst.size)}
              handleOnChange={handleOnChange}
              placeholder="Select size..."
              selected={size}
            />
            <FilterInput
              id="milk-field"
              display={
                size.length > 0 && getOptions(filterConst.milk).length > 1
              }
              field={filterConst.milk}
              options={getOptions(filterConst.milk)}
              handleOnChange={handleOnChange}
              placeholder="Select milk..."
              selected={milk}
            />
            <FilterInput
              id="whip-field"
              display={
                (milk.length > 0 ||
                  (size.length > 0 && hasSingleMilkOption())) &&
                getOptions(filterConst.whip).length > 1
              }
              field={filterConst.whip}
              options={getOptions(filterConst.whip)}
              handleOnChange={handleOnChange}
              placeholder="Select whip..."
              selected={whip}
            />
            {type.length > 0 && (
              <Button
                size="sm"
                className="w-25"
                variant="primary"
                onClick={handleResetFilters}
              >
                Reset
              </Button>
            )}
          </Stack>
        )}
      </div>

      <div className="p-3 mt-4 square border border-4">
        <h5 className="mb-4">
          {!filterdData && <>Choose a drink above</>}
          {filterdData && <>Your drink's nutritional info:</>}
        </h5>

        <table className="table table-sm table-borderless m-0">
          <tbody>
            {[
              ["Calories", "", "Calories"],
              ["Caffeine", "mg", "Caffeine (mg)"],
              ["Sugar", "g", "Sugar (g)"],
              ["Total Fat", "g", "Total Fat (g)"],
              ["Size", "", "Serving Size"],
              ["Cholesterol", "mg", "Cholesterol (mg)"],
              ["Sodium", "mg", "Sodium (mg)"],
            ].map((field) => (
              <tr key={field[0]}>
                <td style={{ width: "155px" }}>
                  <strong>{field[0]}</strong>
                </td>
                <td>
                  {(filterdData && (
                    <>
                      {filterdData[field[2]]}
                      {field[1]}
                    </>
                  )) ||
                    "–"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default App;
